import {getAPI, postAPI} from "./fetch";

export const loginSettingSetup = async (userData) => {
  const userEmail = document.getElementById('user_email')
  if (userEmail) {
    userEmail.value = ''
  } else {
    // emailが無いのは別画面に遷移済みの可能性があるのでなにもしない
    return
  }

  const loadingElement = document.getElementById('loading-element')
  loadingElement.style.display = 'block'

  const password = ['user_current_password', 'user_password', 'user_password_confirmation']
  password.forEach(id => {
    const element = document.getElementById(id)
    if (element) {
      element.disabled = !userData.email
      element.value = ''
    }
  })

  const currentMailaddress = document.getElementById('currentMailaddress')
  if (currentMailaddress) {
    currentMailaddress.innerText = userData.email || ''
  }
  // console.log('#####', userData.email)

  // SNS連携状況の反映
  const providers = ['line', 'twitter', 'facebook', 'google']
  providers.forEach(provider => {
    const isAlignment = userData.alignment_to_sns[provider] === 1
    const btn = document.getElementById(`btn_${provider}_${isAlignment ? 'release' : 'alignment'}`)
    if (btn) {
      btn.style.display = 'inline-block'
    }
  });

  loadingElement.style.display = ''
}

export const userSettingSetup = async (userData) => {
  const name = document.getElementById("user_profile_name")
  if (name) {
    name.value = userData.name
  } else {
    // nameが無いのは別画面に遷移済みの可能性があるのでなにもしない
    return
  }

  const loadingElement = document.getElementById('loading-element')
  if (loadingElement) {
    loadingElement.style.display = 'block'
  }

  setCountryCode(userData.profile?.country_code)
  await setStateCode(userData.profile?.country_code, userData.profile?.state_code)

  const localeSelect = document.getElementById('user_profile_locale')
  for (const el of Array.from(localeSelect.children)) {
    if (el.value === userData.profile?.locale) {
      el.selected = true
    }
  }

  const timezoneSelect = document.getElementById('user_profile_time_zone')
  for (const el of Array.from(timezoneSelect.children)) {
    if (el.value === userData.profile?.time_zone) {
      el.selected = true
    }
  }

  if (userData.profile?.icon_url) {
    const icon = document.getElementById('icon_image')
    icon.src = userData.profile.icon_url
  }

  loadingElement.style.display = ''
}

export const setCountryCode = async (currentCountry) => {
  const countrySelect = document.getElementById('user_profile_country_code')

  const opt = document.createElement('option')
  opt.value = ''
  opt.text = '------'
  opt.selected = !currentCountry
  opt.disabled = true
  countrySelect.appendChild(opt)

  const response = await getAPI({ url: '/api/mst/country/list' })
  const countries = response.data.data
  countries.forEach(country => {
    const option = document.createElement('option')
    option.value = country.alpha2
    option.text = country.name
    option.selected = currentCountry === country.alpha2
    countrySelect.appendChild(option)
  })
}

export const setStateCode = async (countryCode, currentState) => {
  if (!countryCode) {
    return
  }
  // const countrySelect = document.getElementById('user_profile_country_code')
  const stateSelect = document.getElementById('user_profile_state_code')
  while (stateSelect.lastChild) {
    stateSelect.removeChild(stateSelect.lastChild);
  }

  const opt = document.createElement('option')
  opt.value = ''
  opt.text = '------'
  opt.selected = !countryCode || !currentState
  opt.disabled = true
  stateSelect.appendChild(opt)

  const response = await getAPI({ url: '/api/mst/country/subdivision/list', params: { locale: countryCode } })
  const states = response.data.data
  states.forEach(state => {
    const option = document.createElement('option')
    option.value = state.code
    option.text = state.name_ja
    option.selected = currentState === state.code
    stateSelect.appendChild(option)
  })
}
