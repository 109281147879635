// gatsby-browser.js
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';
import './src/styles/global/_reboot.scss';
import './src/styles/global/_base.scss';
import "@fortawesome/fontawesome-svg-core/styles.css"
import axios from "axios";
import queryString from "query-string";
import {navigate} from "gatsby-plugin-react-intl";
import {isLoggedIn, logout, userVerify, verifyMail, verifyPasswordToken} from "./src/services/auth";
import {userSettingSetup, loginSettingSetup} from "./src/services/users";
import {snsAlignment} from "./src/services/SNSAlignment";
import {getAPI} from "./src/services/fetch";
import {contentPathHelper} from "./src/utils/contentPathHelper";

export const onRouteUpdate = async ({ location }) => {
  // console.log('#### onRouteUpdate ###', location)

  // ログイン処理
  checkLogin()

  // 非ログイン状態で開けないページにきたらルートへリダイレクト
  if (!isLoggedIn()) {
    let isJump = false
    const list = ['/users/setting', '/users/loginsetting', '/users/logout']
    list.forEach(path => {
      if (location.pathname.includes(path)) {
        navigate('/')
        isJump = true
      }
    })
    if (isJump) {
      return
    }
  }

  if (isLoggedIn()) {
    const response = await getAPI({ url: '/api/user'})
    const userData = response.data.data

    checkArtist(userData)

    if (location.pathname.includes('/users/setting')) {
      await userSettingSetup(userData)
    }
    if (location.pathname.includes('/users/loginsetting')) {
      await loginSettingSetup(userData)
    }
    if (location.pathname.includes('/users/logout')) {
      await logout()
      await navigate('/')
    }
  }

  // 開催中試合の取得
  await getOpeningMatch(location)

  // 投票ページ処理
  if (location.pathname.includes('/vote/')) {
    await votePagePreprocess(location)
  }

  // ひとリミッツスコアデータの取得
  if (location.pathname.includes('/news/')) {
    await getHitolimitsScoreData(location)
  }

  // ユーザー登録メール認証
  if (location.pathname.includes('/users/register')) {
    await certificationMailaddressRegistration(location)
  }

  // パスワードリセット
  if (location.pathname.includes('/users/password/reset')) {
    await certificationPasswordReset(location)
  }

  // メールアドレス変更
  if (location.pathname.includes('/users/mail/certification')) {
    if (location.pathname.includes('/en/')) {
      return
    }
    await certificationMailaddressChange(location)
  }

  // SNS認証判定処理
  if (location.pathname.includes('/callback/')) {
    if (location.pathname.includes('/en/')) {
      return
    }
    if (location.pathname.includes('/invalid')) {
      return
    }
    await snsAlignment(location)
  }
};

const checkLogin = () => {
  const isLogin = isLoggedIn();
  ['menu-logged-in', 'burger-menu-logged-in'].forEach(name => {
    const element = document.getElementById(name)
    if (element) {
      element.style.display = isLogin ? 'block' : 'none'
    }
  });
  ['menu-not-login', 'burger-menu-not-login'].forEach(name => {
    const element = document.getElementById(name)
    if (element) {
      element.style.display = !isLogin ? 'block' : 'none'
    }
  })
}

const checkArtist = (userData) => {
  const isArtist = userData.artist && userData.artist.status === 2;
  ['menu-artist', 'burger-menu-artist-1', 'burger-menu-artist-2'].forEach(name => {
    const element = document.getElementById(name)
    if (element) {
      element.style.display = isArtist ? 'block' : 'none'
    }
  });
  ['menu-not-artist', 'burger-menu-not-artist'].forEach(name => {
    const element = document.getElementById(name)
    if (element) {
      element.style.display = !isArtist ? 'block' : 'none'
    }
  })
}

const certificationMailaddressRegistration = async (location) => {
  const { p } = queryString.parse(location.search)
  try {
    const response = await userVerify({ p })
    if (response.data.result !== 0) {
      await navigate('/users/invalid')
    }
  } catch (e) {
    await navigate('/users/invalid')
  }
}

const certificationMailaddressChange = async (location) => {
  const { token } = queryString.parse(location.search)
  try {
    const response = await verifyMail({ token })
    if (response.data.result !== 0) {
      await navigate('/users/invalid')
      return
    }
    await navigate('/users/mail/complete')
  } catch (e) {
    await navigate('/users/invalid')
  }
}

const certificationPasswordReset = async (location) => {
  const { p, email } = queryString.parse(location.search)
  try {
    const response = await verifyPasswordToken({ token: p, email })
    console.log('######', response.data)
    if (response.data.result !== 0) {
      await navigate('/users/invalid')
      return
    }
  } catch (e) {
    await navigate('/users/invalid')
  }
}

const getHitolimitsScoreData = async (location) => {
  if (location.pathname === '/news' || location.pathname === '/news/') {
    return
  }
  if (location.pathname.includes('/category')) {
    return
  }

  const serviceId = process.env.MICROCMS_SERVICE_ID
  const apiKey = process.env.API_KEY
  let pathName = location.pathname
  if (location.pathname.substring(0, 5) !== '/news') {
    if (location.pathname.substring(0, 3) === '/en') {
      pathName = location.pathname.substring(3)
    } else {
      return
    }
  }
  const news = await axios.get(`https://${serviceId}.microcms.io/api/v1${pathName}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-MICROCMS-API-KEY': apiKey
    }
  })
  if (news.data.body) {
    const hitolimitsSet = news.data.body.filter(e => e.fieldId === 'hitolimits_set')
    hitolimitsSet.forEach(set => {
      set.hitolimits.forEach(e => {
        const element = document.getElementById(`vote-${e.hitolimits.id}`)
        if (element) {
          if (e.hitolimits.vote_point) {
            element.innerText = e.hitolimits.vote_point
          } else {
            element.innerText = '0'
          }
        }
      })
    })
  }
}

const getOpeningMatch = async (location) => {
  if (location.pathname !== '/') {
    return
  }
  const apiKey = process.env.LIMITS_API_KEY
  const pathName = process.env.LIMITS_API_URL
  const response = await axios.get(`${pathName}/api/match/?match=start`, {
    headers: {
      'Content-Type': 'application/json',
      'LIMITS-BATTLESYSTEM-API-KEY': apiKey
    }
  })
  if (response.data && response.data.match_infos && response.data.match_infos.length > 0) {
    const element = document.getElementById(`vote-panel`)
    element.style.display = 'block'
    element.href = `/vote/${contentPathHelper(response.data.match_infos[0].match_id)}`
  }
}

const votePagePreprocess = async (location) => {
  if (!location.pathname.includes('/vote')) {
    return
  }

  // 試合情報の取得
  const apiKey = process.env.LIMITS_API_KEY
  const pathName = process.env.LIMITS_API_URL
  const url = location.href.split('/')
  const id = url[url.length - 1] ? url[url.length - 1] : url[url.length - 2]
  const responseMatch = await axios.get(`${pathName}/api/match/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'LIMITS-BATTLESYSTEM-API-KEY': apiKey
    }
  })
  const matchData = responseMatch.data?.data
  // console.log('######### match data', matchData)
  if (matchData) {
    // 投票可能状態チェック
    if (matchData.match_status === 'end') {
      navigate('/')
    }

    // テーマ設定
    const abstract = document.getElementById('theme-abstract')
    abstract.innerText = matchData.abstract
    const concrete = document.getElementById('theme-concrete')
    concrete.innerText = matchData.concrete

    // 青アーティスト設定
    const blueIcon = document.getElementById('blue-artist-icon')
    const blue = matchData.artist.blue[0]
    blueIcon.src = blue.image_url ? blue.image_url : blue.image.url
    const blueName = document.getElementById('blue-artist-name')
    blueName.innerText = blue.name
    blueName.href = `../artist/${blue.id}/`

    // 赤アーティスト設定
    const redIcon = document.getElementById('red-artist-icon')
    const red = matchData.artist.red[0]
    redIcon.src = red.image_url ? red.image_url : red.image.url
    const redName = document.getElementById('red-artist-name')
    redName.innerText = red.name
    redName.href = `../artist/${red.id}/`

    const data = localStorage.getItem(`limits-user-${id}`)
    if (data) {
      const userData = JSON.parse(data)
      const element = document.getElementById(`artist-box-${userData.voted}`)
      if (userData.voted === 'blue') {
        element.style.backgroundColor = 'rgba(0, 120, 206, 0.5)'
      } else if (userData.voted === 'red') {
        element.style.backgroundColor = 'rgba(202, 0, 108, 0.5)'
      }
    }
  }
}
