import queryString from "query-string";
import {postAPI} from "./fetch";
import {navigate} from "gatsby-plugin-react-intl";
import {setUser} from "./auth";

export const snsAlignment = async (location) => {
  const queryParams = queryString.parse(location.search)
  if (location.pathname.includes('line')) {
    await alignment('line', queryParams)
  } else if (location.pathname.includes('twitter')) {
    await alignment('twitter', queryParams)
  } else if (location.pathname.includes('facebook')) {
    await alignment('facebook', queryParams)
  } else if (location.pathname.includes('google')) {
    await alignment('google', queryParams)
  }
}

const alignment = async (provider, queryParams) => {
  const { code } = queryParams
  const challenge = sessionStorage.getItem('twitter-challenge')
  if (challenge) {
      sessionStorage.removeItem('twitter-challenge')
  }
  const key = sessionStorage.getItem('sequence')
  try {
    let url = `/api/${provider}/token`
    if (key === 'alignment') {
      url = `/api/${provider}/alignment`
      sessionStorage.removeItem('sequence')
    }
    console.log('##########', url, code)
    const response = await postAPI({ url, params: { code, challenge: challenge ? challenge : undefined } })
    if (response.data.result !== 0) {
      navigate('/callback/invalid')
      return
    }

    setUser({ token: response.data.token })
    if (response.data.status === 1) {
      navigate('/users/nameregistration')
      return
    }
    if (key === 'alignment') {
      sessionStorage.setItem('sns-alignment-success', provider)
      navigate('/users/loginsetting')
    } else {
      navigate('/')
    }
  } catch (e) {
    console.error(e)
    if (key === 'alignment' && e.response.data.result === -1) {
      sessionStorage.setItem('sns-alignment-failed', provider)
      navigate('/users/loginsetting')
      return
    }
    navigate('/callback/invalid')
  }
}
