import {postAPI, getAPI} from "./fetch";

export const isBrowser = () => typeof window !== 'undefined'

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.token
}

export const getUser = () => {
  if (!isBrowser()) {
    return {}
  }
  const userData = window.localStorage.getItem('userdata')
  if (userData) {
    return JSON.parse(userData)
  } else {
    return {}
  }
}

export const setUser = (user) => window.localStorage.setItem('userdata', JSON.stringify(user))

export const login = async ({ email, password }) => {
  let result = null
  await postAPI({ url: '/api/login', params: { email, password }})
    .then((response) => {
      console.log('[Limits-api] login success', response)
      setUser({
        token: response.data.token
      })
      result = response
    })
    .catch((error) => {
      console.log('[Limits-api] login failed', error)
      throw error
    });
  return result
}

export const signup = async ({ email }) => {
  await postAPI({ url: '/api/register', params: { email }})
    .then((response) => {
      const data = JSON.stringify({ uuid: response.data.uuid })
    })
    .catch((error) => {
      throw error
    });
}

export const logout = async (callback) => {
  await postAPI({ url: '/api/logout'})
  setUser({})
  if (callback) {
    callback()
  }
}

export const userVerify = async ({ p }) => {
  return await postAPI({ url: '/api/verify/check', params: { p }})
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    });
}

export const passwordRegist = async ({ p, password, password_confirmation }) => {
  return await postAPI({ url: '/api/verify', params: { p, password, password_confirmation }})
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    });
}

export const verifyMail = async ({ token }) => {
  return await getAPI({ url: '/api/verify/email', params: { token }})
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    });
}

export const verifyPasswordToken = async ({ token, email }) => {
  return await postAPI({ url: '/api/user/password/reset/check', params: { token, email }})
    .then((response) => {
      return response
    })
    .catch((error) => {
      throw error
    });
}
