import axios from "axios";
import {getUser} from "./auth";

const apiKey = process.env.LIMITS_API_KEY;
const apiUrl = process.env.LIMITS_API_URL;

export const getAPI = ({url, params}) => {
  const headers = {
    'Content-Type': 'application/json',
    'LIMITS-BATTLESYSTEM-API-KEY': apiKey
  }
  const user = getUser()
  if (user.token) {
    headers['Authorization'] = `Bearer ${user.token}`
  }
  return axios.get(apiUrl + url, {  headers, params })
}

export const postAPI = ({url, params}) => {
  const headers = {
    'Content-Type': 'application/json',
    'LIMITS-BATTLESYSTEM-API-KEY': apiKey
  }
  const user = getUser()
  if (user.token) {
    headers['Authorization'] = `Bearer ${user.token}`
  }
  return axios.post(apiUrl + url, params, { headers })
}
